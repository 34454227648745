@import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: "Amiri";
  src: url("./assets/fonts/Amiri/Amiri-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Readex Pro", sans-serif;
  src: url("./assets/fonts/Readex_Pro/ReadexPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Andalus Regular";
  src: url("./assets/fonts/andalus/AndalusArabicFont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Jamhuria Regular";
  src: url("./assets/fonts/jomhuria/Jomhuria-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "fontislamic";
  src: url("./assets/fonts/font-islamic/FontIslamicColor-r1XO.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  src: url("./assets/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal/Tajawal-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cairo";
  src: url("./assets/fonts/Cairo/Cairo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Naskh";
  src: url("./assets/fonts/notoNaksh/NotoNaskhArabic-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Mcs Book Title 4";
  src: url("https://www.fontsaddict.com/fontface/mcs-book-title-4.TTF");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("./assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("./assets/fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("./assets/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url("./assets/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("./assets/fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("./assets/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

.amiri-regular {
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
}
 
.amiri-bold {
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: normal;
}
 
.amiri-regular-italic {
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: italic;
}
 
.amiri-bold-italic {
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: italic;
}




.facility_tag {
  padding: 3px 10px;
  background-color: #e6e6e6;
  margin-right: 10px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  min-width: 90px;
  justify-content: space-between;
  margin-bottom: 3px;
}

.facility_tag span {
  display: inline-block;
  font-size: 12px;
  /* Adjusts width based on text length */
  margin-right: 5px;
  color: #636363;
  /* Add spacing between text and 'x' */
}

.css-1yn2e29-InputColor {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100% !important;
  height: 34px !important;
  padding: 4px;
  background-color: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 3px;

  user-select: none;
}

.css-1vpurlb-InputColor {
  display: block;
  width: 20% !important;
  height: 100% !important;
  cursor: pointer;
}

.facility_tag p {
  font-weight: bold;
  color: #007b85;
  cursor: pointer;
}

.image-previewer-modal .ant-modal-body {
  padding: 0 !important;
}

.all-applicationTable-select > .ant-select-selector {
  height: 35px !important;
  /* border-radius: 30px !important;
  background-color: red; */
  max-width: 200px !important;
}

.documentFormItemRow {
  padding: 2rem;

  background-color: #fbfbfb;

  margin: 0px;
}

.documentExpiryDateWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.documentInputCardItem {
  margin-bottom: 10px;
  border-radius: 10px;
}

.tcc_reson_tag {
  color: #007b85;
}

.custom_tag {
  color: #007b85;
  cursor: pointer;
  text-decoration: underline;
}

.ant-form-item-explain-error {
  color: #ff4d4f;
  margin-bottom: 40px;
}

.form_custom_error {
  color: #ff4d4f;
  margin-top: -21px !important;
}

.ant__primary__button {
  height: 40px;
  min-width: 130px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.ant-table-row-expand-icon-cell {
  display: none;
}

.row_expand_container {
  display: flex;
  align-items: center;
  width: 110px;
  cursor: pointer;
}

.genenral_container {
  margin: 20px;
}

.genenral_container p {
  margin: 0;
  padding: 0;
}

.date__custom_width {
  width: 100%;
}

.payment_click_link {
  cursor: pointer;
  color: #007b85;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.feature_icon_background {
  position: relative;
  width: 60px;
  /* Set the width */
  height: 50px;
  /* Set the height */
  /* Adjust border-radius if needed for rounded corners */
  overflow: hidden;
  /* Hide overflow content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  /* Adjust the opacity (fourth value) as needed */
}

.feature_icon_background img {
  position: relative;
  display: block;
  width: 80%;
  object-fit: contain;

  /* Align the image vertically */
}

.form_item__subtitle {
  margin-top: -20px;
  font-size: 12px;
  color: #636363;
}

.discount_remove__button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.discount_remove__button p {
  padding: 0;
  margin: 0;
  color: #ff4d4f;
  cursor: pointer;
}
.sub-description {
  color: #888;
  font-size: 10px;
  margin-bottom: 10px;
}

.pdf__button {
  width: 100px;
  align-self: flex-end;
  margin: 10px 0;
}

.chart-legend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.legend-item {
  margin: 10px 10px;
}
.apexcharts-bar {
  margin-right: 10px; /* Adjust this value to control the spacing between bars */
}
@media screen and (max-width: 413px) {
  .filterColumn {
    width: 100% !important;
    max-width: 45vw !important;
    min-width: 160px !important;
  }

  .readOnlyDocCoverImage {
    width: 35%;
  }

  .admissionStatusFormCol {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .generalNotificationTriggerColumn {
    width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .compactFormItemCol {
    width: 100% !important;
  }

  .applicationDetailsTabForm {
    background-color: transparent !important;
  }

  .compactFormItemRow {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 10px 0;
  }

  .documentFormItemRow {
    background-color: transparent;
    padding: 1rem 0;
  }

  .documentExpiryDateWrapper {
    flex-direction: column;
    height: 100%;
  }

  .documentInputCardItem {
    border-radius: 0;
  }

  .readOnlyFormItemCol {
    width: 100% !important;
  }
}

.ant-checkbox-inner,
.ant-checkbox-input {
  /* transform: scale(1.2); */
  border-color: #007b85;
}

.sidebar .nav li > a {
  line-height: unset !important;
}





.sidebar {
  transition: width 0.3s ease;
  width: 250px; /* Expanded width */
}

.sidebar.collapsed {
  width: 80px; /* Collapsed width */
}

.sidebar .logo img {
  max-width: 100%;
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .logo img {
  opacity: 0; /* Hide logo in collapsed state */
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.sidebar.collapsed .nav-link p {
  display: none; /* Hide text in collapsed state */
}

.sidebar.collapsed .nav-link img {
  margin-right: 0; /* Remove margin when collapsed */
}


.main-panel {
  position: relative;
  height: 100vh !important;
  float: right;
  width: calc(100% - 260px);
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel.expanded {
  width: calc(100% - 80px); /* Adjust width when sidebar is collapsed */
}
.customer-search-container {
  position: relative;
  margin-bottom: 20px;
}

.customer-search-container h5 {
  font-size: 18px;
}

.search-field {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}

.search-field input {
  border: none;
  outline: none;
  width: 100%;
}

.search-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.customer-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 100;
  /* to ensure the dropdown is above other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rebeccapurple;
}

.customer-entry {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.customer-entry:last-child {
  border-bottom: none;
}

.create-new-customer {
  padding: 3px 0px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  border-style: dashed;
  border: 1px dashed #007b85;
  font-size: 14px;
  color: #007b85;
}

.customer-search-result {
  padding: 5px 5px;
  cursor: pointer;
}

.customer-search-result:hover {
  background-color: #eee;
}

.customer-search-modal {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  z-index: 999;
  width: 100%;
}

.customer-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-section-header-icon {
  cursor: pointer;
  margin-right: 5px;
}

.customer-search_result_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spiner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.card__snippet h4 {
  font-size: 18px !important;
  color: #636363;
}

.price_tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price_tag p {
  color: #081735;
  font-weight: 500;
  /* width: 110px; */
}

.price_tag b {
  color: #081735;
}

.scc_monthly_comparison_container {
    padding: 10px 10px;
}

.positive_beh_container {
    /* height: 120px; */
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    transition: box-shadow 0.3s ease;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
}

.positive_beh_container:hover {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}

.positive_beh_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 10px 10px;
}

.positive_beh_report {
    width: 60%;
    height: 100%;

}

.positive_beh_icon {
    height: 70%;
}

.positive_beh_icon img {
    height: 45px;
    object-fit: contain;
    margin-bottom: 20px;
}



.positive_beh_title span {
    font-size: 16px;
    color: #9A9A9A;
    padding: 0;
    margin: 0;
    font-weight: 400;
}

.positive_beh_count {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 22px;
    color: #081735;
    height: 100%;
    font-weight: bold;
    opacity: 0.9;
}



.negative_beh_container {
    /* height: 120px; */
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    transition: box-shadow 0.3s ease;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
}

.negative_beh_container:hover {
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}

.chart_title {
    display: flex;
    align-items: center;
    justify-content: center;

}

.chart_title p {
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
    font-size: 14px;
    color: #081735;
}

.offense_level {
    height: 40px;
    border-radius: 20px;
    background-color: #ecdada;
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFA8A8;
    font-size: 14px;

}

.offense_level2 {
    height: 40px;
    border-radius: 20px;
    background-color: #ecdada;
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F97878;
    font-size: 14px;

}

.offense_level4 {
    height: 40px;
    border-radius: 20px;
    background-color: #ecdada;
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #CB5858;
    font-size: 14px;

}
.stageStatsCardFooterWrapper {
  display: flex;

  flex-wrap: wrap;
  background: #fbfbfb;
  padding: 10px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.stageStatsCardFooterWrapper p {
  margin: 0 !important;
  padding: 0 !important;
}

.stageStatsStatusPending {
  cursor: pointer;
  /* border-radius: 3px; */
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 10px;
  flex: 1;
  justify-content: center;
  /* background: #f2bc62 0% 0% no-repeat padding-box; */
}

.stageStatsStatusPending p {
  color: #585858;
  opacity: 1;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0px;
}

.stageStatsStatusInprogress {
  cursor: pointer;
  display: flex;
  gap: 25px;
  align-items: center;

  flex: 1;
  justify-content: center;
}

.stageStatsStatusInprogress p {
  color: #585858;
  opacity: 1;
  font-size: 12px;
  text-align: center;
}

.stageStatsStatusCompleted {
  cursor: pointer;

  align-items: center;
  gap: 25px;
  display: flex;
  padding: 10px;
  flex: 1;
  justify-content: center;
}

.stageStatsStatusCompleted p {
  color: #585858;
  opacity: 1;
  font-size: 12px;
  text-align: center;
}

.currentStageStatics {
  line-height: 1;
}

.stageCardFooterItem {
  flex: auto;
  position: relative;
  /* padding-left: 5px; */
}

.stageCardFooterItem:after {
  content: " ";
  position: absolute;
  /* border-left: 1px #f4f3ef solid; */
  top: 25%;
  right: 20%;
  height: 55%;
  margin-top: auto;
  margin-bottom: auto;
}

.last:after {
  display: none !important;
  content: " ";
}

.footerItemTitle {
  padding: 0 2rem;
}

.stageCardFooterItem p:first-child {
  font-size: 12px;
  color: #9a9a9a;
}


.stageStatusTitle {
  height: 37px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  vertical-align: middle !important;
  justify-content: center !important;
}

/* textAlign: "center",
                          height: "37px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textAlign: "center",
                          lineHeight: 1, */
.title_with_line_container {
  /* margin: 30px 10px; */
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 10px;
}

.line_side {
  flex: 1;
  /* Grow to fill remaining space */
  height: 2px;
  background-color: #e6e6e6;
  margin: 0 10px;
}

.line_title {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: black;
  white-space: nowrap;
  /* Prevent title from wrapping */
  overflow: hidden;
  /* Hide any overflow */
  text-overflow: ellipsis;
  /* Display ellipsis for overflow */
}

.custom_card_container {
  padding: 5px 5px 5px 5px;
  background-color: white;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.custom_card_container:hover {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}

.custom_table_container {
  /* padding: 20px 10px; */
  /* height: 220px; */
  width: 100%;
  background-color: #fbfbfb;
  margin: 40px 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.table_indentifier {
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0efec;
  font-size: 14px;
  color: #081735;
}

.table_header_data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #9c9c99;
  height: 44px;
}

.table_header_value {
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 44px; */
}

.table_header_value {
  border-bottom: 1px solid #9c9c99;
}

.table_header_value:last-child {
  border-bottom: none;
  height: 45px;
  /* Remove bottom border for the last table_header_value */
}

/* Remove bottom border for the last child */

.table_month_col {
  border-left: 1px solid #9c9c99;
  padding: 10px 5px;
  width: 100%;
  font-size: 14px;
  color: #081735;
  font-weight: 500;
}

.table_month_value {
  border-left: 1px solid #9c9c99;
  padding: 5px 5px;
  width: 100%;
  font-size: 14px;
  color: #9c9c99;
  height: 100%;
}

.school_name {
  font-size: 14px;
  border-left: 0;
  color: #081735;
  min-width: 120px;
}

/* report table */

.report_table_container {
  /* padding: 20px 10px; */
  /* height: 244px; */
  width: 100%;
  background-color: #fbfbfb;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.report_table_indentifier {
  height: 49px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0efec;
  font-size: 14px;
  color: #081735;
}

.report_table_header_data {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #9c9c99;
  height: 49px;
}

.report_table_header_value {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
}

.report_table_header_value {
  border-bottom: 1px solid #9c9c99;
}

.report_table_header_value:last-child {
  border-bottom: none;
  height: 49px;
  /* Remove bottom border for the last table_header_value */
}

.report_table_title_col {
  border-left: 1px solid #9c9c99;
  padding: 5px 5px;
  width: 100%;
  font-size: 10px;
  color: #081735;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
}

.report_table_value {
  border-left: 1px solid #9c9c99;
  padding: 5px 5px;
  width: 100%;
  font-size: 12px;
  color: #9c9c99;
  height: 100%;
  display: flex;
  align-items: center;
}

.reported_school_name {
  font-size: 12px;
  border-left: 0;
  color: #081735;
  width: 120%;
}

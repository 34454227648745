.template_modal {
    width: 80% !important;
}

.export_application_modal {
    width: 70% !important;
}

.custom_events_modal {
    width: 35% !important;
}
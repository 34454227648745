.applicationsStatusesList {
  display: flex;
  border-radius: 10px;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  flex-wrap: wrap;
}
.eServiceList {
  margin-bottom: 1rem;
  flex-wrap: nowrap !important;
  box-shadow: none;
  min-width: 100%;
}
.eServiceItem {
}
.eServiceItem::after{
  border-left: none !important;
}
.applicationsStatusItemSelected {
  background: #007b85 !important;
}
.applicationsStatusItemSelected p {
  color: #f4f3ef !important;
  
}
.applicationsStatusItem {
  padding: 12px 20px;
  background: #fcfcfc;
  flex: auto;
  transition: background-color 0.5s ease;
  cursor: pointer;
  min-width: 100px;

  position: relative;
}
.applicationsStatusItem:after {
  content: " ";
  position: absolute;
  border-left: 1px #f4f3ef solid;
  top: 25%;
  right: 0;
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;
}
.applicationsStatusItem:hover:after {
  opacity: 0;
}

.applicationsStatusItem:hover {
  background: #007b85;
  color: #f4f3ef !important;
}

.applicationsStatusItem:first-child {
  border-radius: 10px 0 0 10px;
}
.applicationsStatusItem:last-child {
  border-radius: 0 10px 10px 0;
}

.applicationsStatusItem p {
  margin: 0;
}

.applicationsStatusItem p:first-child {
  color: #007b85;
  font-weight: 500;
}
.applicationsStatusItem:hover p:first-child {
  color: #f4f3ef !important;
}

.applicationsStatusItem p:last-child {
  font-size: 17px;
  font-weight: 500;
}
/* .applicationsStatusItem p:last-child:after {
  /* content: ">";
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px; */
/* } * */

.dropdown {
  border: 1px solid #007b85 !important;
  border-radius: 3px;
  background-color: white;
}

.ant-select-selection-placeholder {
  color: darkgray;
}
.dashboardViewApplicationFilters {
  display: flex;
  gap: 20px;
}

.dashboardApplicationFilterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
}

@media only screen and (max-width: 900px) {
  .applicationsStatusItem {
    padding: 12px 20px;
    background: #fcfcfc;
    /* flex: auto; */
    -webkit-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    cursor: pointer;
    min-width: unset;
    width: 25%;
    position: relative;
    /* flex: 1; */
  }
  .applicationsStatusesList {
    display: flex;

    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 600px) {
  .dashboardViewApplicationFilters {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
  }
  .dashboardApplicationFilterWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

  .tab-card-container {
  }
  .tab-card-container p {
    margin: 0;
  }
  .tab-card-container > .ant-tabs-card .ant-tabs-content {
    background: #EDEDED;
    margin-top: -16px;
    min-height: 90vh;

  }
  .tab-card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #EDEDED;
  }
  .tab-card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .tab-card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .tab-card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .tab-card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #EDEDED;
    border-color: #EDEDED;
    border-radius: 20px;
  }
  .tab-card-container > .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border: #EDEDED;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1rem;
    opacity: 1 !important;
    transition: opacity 0.4s ease-in;
  }
  .tab-card-container > .ant-tabs-card.ant-tabs-top > .ant-tabs-nav h6 {
    color: #C5C7CF;
    }
  .tab-card-container > .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active h6 {
  color: #007575;

  }
  .tab-card-container > .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    opacity: 0.6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1rem;
    border: #EDEDED;
  }
  .tab-card-container h6 {
      margin: 0;
      padding: 0;

  }
  .tab-badge {
    background-color: #cfcdcd;
    border-radius: 100%;
    padding: 0.2rem;
    margin-left: 0.3rem;
    min-width: 20px;
    display: flex;
    justify-content: center;
  }
  .tab-badge p {
    color: #ffff;
    font-size: x-small;
  }
  .tab-card-container > .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active .tab-badge {
    background-color: #007575;
  }
.asrEserviceTab .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: #D6DBE4 1px solid;
  }
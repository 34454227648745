.notificationTableColumn {
  width: 16%;
}
.notificationTableColumn1 {
  width: 25%;
}
.actionTableColumn {
  width: 10%;
}
.stause {
  padding: 5px 0 0 5px;
}
.stause:hover {
  background-color: #f7f6ff;
}
.titleTab {
  background-color: green;
}

@media screen and (max-width: 800px) {
  .notificationTableColumn {
    min-width: 115px;
    padding: 12px;
  }
  .notificationTableColumn1 {
    min-width: 115px;
    padding: 12px;
  }
  .actionTableColumn {
    /* min-width: 115px; */
    min-width: 70px;
    padding: 4px;
  }
  .notificationTriggerCardWrapper {
    width: max-content !important;
  }
}

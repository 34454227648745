.expanded__row__header {
    display: flex;
    width: 100%;
    align-items: center;
    height: 50px;
    background-color: #FAFAFA;
    border-radius: 5px;
}

.expanded__row__header_column {
    width: 25%;
    padding: 10px 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.expanded__row__header_column p {
    font-weight: 500;
}

.expanded__row {
    display: flex;
    width: 100%;
    align-items: center;
}

.expanded__row__value {
    width: 25%;
    padding: 5px 20px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.expanded__row p {
    padding: 0;
    margin: 0;
}

/* add new bookings */
.schedule_and_addmore_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.schedule_and_addmore_section span {
    color: #007B85;
    cursor: pointer;
}
/* Calendar.css */
.calendar_container {

    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

}

.calendar_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 10px;
}

.calendar_sub_actions {
    display: flex;
    align-items: center;

}

.calendar_current_month {
    margin: 0 20px;
    font-size: 20px;
    font-weight: bold;
    color: #081735;
}

.calendar_filters {
    margin: 20px 10px 0px 10px;
    /* top right bottom left */
}

.calendar {
    width: 100%;
    margin: 0 auto;
    padding: 5px;


}

.monthly-view {
    border-radius: 5px;
    padding: 4px;
    /* background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.day-names {
    display: flex;
    background-color: #007B85;
    border-bottom: 1px solid #ccc;
    align-items: center;
    justify-content: center;
}

.days-label {
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    width: 100%;
    padding: 5px 0;
    color: white;

}



.days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.day {
    border: 0.5px solid #ccc;
    text-align: center;
    height: 140px;
    width: auto;
    position: relative;
}

.day-number {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 12px;
}

.day-label {
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 4px;
    position: relative;
}

.today {
    position: relative;
}

.empty-day {
    color: #ccc;
}

.other-month {
    color: #aaa;
}

.today-label {
    font-size: 12px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    color: black;

}



/* events */
.event {
    height: 50px;
    width: 87%;
    margin-bottom: 8px;
    background-color: #ECEDFFA3;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.modal_event {
    height: 80px;
    width: 70%;
    margin-bottom: 3px;
    background-color: #ECEDFFA3;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.event:hover {
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.modal_event:hover {
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.custom_event_title {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: white;
    margin-left: 5px;
}

.custom_event_sub_title {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: white;
    margin-left: 5px;
}

.custom_event_type_title {
    padding: 0;
    margin: 0;
    font-size: 9px;
    color: black;
    margin-left: 5px;
    font-weight: 500;
    margin-top: 1px;
}

.event_detail {
    height: 70%;
    width: 100%;
}

.more_events_indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007B85;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.events_in_modal {
    margin: 10px 20px;
}

.event_modal_current_slot {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.today_date {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: rebeccapurple;
}
.conduct_case_general h3 {
    padding: 0;
    margin: 10px 0;
    font-size: 16px;
}

.scc_general_tabs_container {
    padding: 20px;
}

.scc_title p {
    font-size: 14px;
    padding: 3px 0;
    margin: 0;
    color: rgb(50, 48, 48);
}

.scc_value p {
    font-size: 14px;
    color: black;
    padding: 3px 0;
    margin: 0;
    /* text-align: justify; */
}

.scc_divider {
    height: 1px;
    width: 100%;
    background-color: #9F9F9F;
    margin: 5px 0;
}

.scc_conditional_text {
    font-weight: bold;
}

.scc__genenral_container {
    margin: 0;
}

.scc__genenral_container p {
    margin: 0;
    padding: 0;
}

.conduct_case_inquiry_container {
    background-color: white;
    border-bottom: 1px solid rgb(190, 185, 185);
    padding-bottom: 10px;
}

.conduct_case_inquiry_container p,
span {
    margin: 0;
    padding: 0;
}

.conduct_case_inquiry_by {
    font-size: 15px !important;
    /* padding: 3px 0 !important; */
    margin: 0 !important;
    color: rgb(50, 48, 48) !important;
}

.conduct_case_inquiry_at {
    font-size: 12px !important;
}

.conduct_case_inquiry {
    font-size: 15px !important;
    padding: 3px 0 !important;
    margin: 0 !important;
    color: rgb(50, 48, 48) !important;
}

.conduct_case_inquiry {
    font-size: 15px !important;
    padding-bottom: 15px !important;
    margin: 0 !important;
    color: rgb(50, 48, 48) !important;
}

.conduct_case_respond {
    font-size: 15px !important;
    /* padding: 15px 0 !important; */
    margin: 0 !important;
    color: rgb(50, 48, 48) !important;
}

.show_all_button {
    font-size: 15px !important;
    padding: 3px 0 !important;
    margin: 0 !important;
    color: #007b85 !important;
    opacity: 0.8;
}

.show_all_button:hover {
    opacity: 1;
    cursor: pointer;
}
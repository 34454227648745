.uploadedDocumentItemWrapper {
  border-bottom: 1px solid #e6e8f0;
}
.uploadedDocumentItemWrapper:last-child {
  border-bottom: none;
}
.uploadedDocumentThumbnail {
  max-height: 50px;
  max-width: 50px;
}
.uploadedDocumentThumbnail img {
  max-height: 50px;
  max-width: 50px;
}
.documentItemDescWrapper {
  display: flex;
  gap: 40px;

  align-items: center;
  margin: 1rem 0;
  justify-content: space-around;

  flex-wrap: nowrap;
  /* overflow: hidden; */
}

.documentItemTitle {
  /* background-color: red; */
}

.documentItemTitle p {
  color: #8f95b2;
  font-family: Poppins-Regular;
  margin: 0;
}
.documentItemTitle p:nth-child(1) {
  font-family: Poppins-Medium;
  color: #081735;
  margin: 0;
}
.uploadedDocumentItemActions {
/* display: flex; */
  gap: 20px;
  margin: 1rem 0;
  align-items: center;
}
.documentsCommentInput {
  height: 40px;
  border-radius: 7px;
}

.documentCommentTextarea {
  margin-top:-20px !important;
}

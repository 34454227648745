.card-chart {
  .card-header {
    .card-title {
      margin-top: 10px;
      margin-bottom: 0;
    }
    .card-category {
      margin-bottom: 5px;
    }
  }

  .table {
    margin-bottom: 0;

    td {
      border-top: none;
      border-bottom: 1px solid #e9ecef;
    }
  }

  .card-progress {
    margin-top: 30px;
  }

  .chart-area {
    height: 190px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }
  .card-footer {
    margin-top: 15px;

    .stats {
      color: $dark-gray;
    }
  }

  .dropdown {
    position: absolute;
    right: 20px;
    top: 20px;

    .btn {
      margin: 0;
    }
  }
}

.user-search-row {
  margin-bottom: 20px;

  .ant-radio-inner::after {
    background: #ffffff;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      background: #1876cc;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #1876cc !important;
  }

  .ant-radio-group {
    label {
      span {
        color: #1876cc;
        @media only screen and (max-width: 375px) {
          font-size: 12px;
        }
        @media only screen and (max-width: 320px) {
          font-size: 10px;
        }
      }
    }
  }

  .user-search-radio-container {
    margin-top: 10px;
  }
}

/* TableStyles.css */
.negative-behavior {
  color: #ff4d4f;
  font-weight: 500;
}

.positive-behavior {
  color: #007b85;
  font-weight: 500;
}

.distinguished-behavior {
  color: #0527af;
  font-weight: 500;
}

.tableHeaderWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;
  gap: 10px;
}

.tableHeaderWrapper h4 {
  /* background-color: red; */
  margin: 0;
  color: #081735;
  font-family: Arial, Helvetica, sans-serif;
}

.tableHeaderSubTitle {
  color: #8f95b2;
}

.headerActionBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 15px 18px #ecedffa3;
  border-radius: 5px;
  /* background-color: #007b85; */
}
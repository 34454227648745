.fsm__booking_header__containr {
    margin: 20px;
}

.fsm__booking_header__containr p {
    color: #9C9C99;
}

.stageInformation {
    display: flex;
    align-items: center;
}

.__dot {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #007B85;
    margin: 0px 10px;
    padding: 0px;

}

.stageInformation p {
    padding: 0px;
    margin: 0px;
    color: #5b5b5a;
}

.stageInformation span {
    color: #454544;
    font-weight: bold;
}

.booking_user_details p {
    padding: 5px 0;
    margin: 0;
}

.booking_type_info p {
    padding: 5px 0;
    margin: 0;
    text-transform: capitalize;
}

.booking_type_info span {
    color: #454544;
    font-weight: bold;
    opacity: 0.7;
    font-size: 12px;
}

.booking_user_details span {
    color: #454544;
    font-weight: bold;
    opacity: 0.7;
    font-size: 12px;
}

.booking_auto_aproval_tag {
    height: 40px;
    padding: 5px 10px;
    background-color: #F4F3EF;
    width: 120px;
    margin: 5px 0;
    border-radius: 5px;
    color: #141414;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* fsm tabs */

.fsm__tabs__container {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.fsm__tabs__container p {
    padding: 0;
    margin: 0;
}

.fsm_t_details {
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid;
    cursor: pointer;
}

.fsm_t_details p {
    font-size: 22px;
    font-weight: 500;
}

.fsm_t_logs {
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid;
    cursor: pointer;
}

.fsm_t_logs p {
    font-size: 22px;
    font-weight: 500;
}

/* logs */
.fsm__logs__container {
    height: auto;
    width: 100%;
}

.fsm__logs__slug_title p {
    color: #007B85;
    font-size: 26px;
    text-transform: capitalize;
    font-weight: 500;
}

.logsStageStartEndTime {
    font-size: 13px;
    font-weight: bold;
    color: #007575;
}

.booking_stage_internal_status {
    font-size: 16px;
    color: #007575;
    padding: 0;
    margin: 0;
}

.fsm_logs {
    margin-top: 5px !important;

}

.fields_snipets_container {
    margin: 10px 0;
    width: 100%;
    padding: 10px 0;
}



.field_label span {
    font-size: 14px;
    color: #454544;
    padding: 0 5px;
    font-weight: 500;
}

.field_value span {
    font-size: 14px;
    color: #5b5b5a;
    text-transform: capitalize;
}

.field_snipet_row {
    background-color: #FFFFFF;
    width: 100%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 10px 5px;
    margin: 5px 0;
    height: auto;
}

.schedules__row {
    background-color: #FFFFFF;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    padding: 0 10px;
}

.schedule__snipets {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.schedule__snipets p {
    margin: 0;
    padding: 0;
    width: 20%;
    font-weight: bold;
    color: #454544;
}

.schedule__snipet_line {
    height: 1px;
    width: 100%;
    background-color: #9C9C99;
}

.refrence_number {
    font-size: 14px;
    color: #5b5b5a;
}

.view_all {
    color: #007B85 !important;
    font-weight: bold;
    cursor: pointer;
}

.booking_reschedule_button {

    display: flex;
    width: 100%;
    align-Items: center;
    justify-Content: flex-end
}

.booking_reschedule_button p {

    color: #007575;
    text-decoration: underline;
    font-size: 15px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.fsm__logs__spnier {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.schedule__details__container p {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0 !important;
}

.table_record {
    font-size: 12px;
}

.refund_schedule_table {
    margin-bottom: 40px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
}

.refund_schedule_table__header {
    display: flex;
    justify-Content: space-between;
    background-color: #9C9C99;
    height: 30px;
    color: white;
    align-items: center;
}

.refund_schedule_table__header p {
    flex: 1;
    text-Align: center;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.refund_schedule_table__row {
    display: flex;
    justify-Content: space-between;
    height: 40px;
    align-items: center;
}

.refund_schedule_table__row p {
    flex: 1;
    text-Align: center;
    padding: 0;
    margin: 0;
    font-size: 12px;
}
.title-option {
  color: red;
  font-weight: bold;
}

.side-padding {
  padding: 0 2rem;
}

.both-side-padding {
  padding: 2rem;
}

.only-top-padding {
  padding: 2rem;
}

.ant-table {
  border-radius: 8px !important;
}

.ck-editor__editable_inline {
  max-height: 350px;
}

.expand-icon {

  font-size: 40px;
  color: #007575;
  align-self: center;
  height: 25px;
}

.antCardBorder {
  border-radius: 7px;
  height: 100%;
}

.antCard h1,
h2,
h3,
h4,
h5,
p {
  /* margin: 7px 0; */
}

.applicationDetailsTab {
  padding: 1rem 0;
}

.applicationDetailsTab .ant-tabs-nav-scroll {
  display: flex !important;
  justify-content: center !important;
  padding: 2rem 0;
  background-color: #fbfbfb;
  border-radius: 7px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.applicationDetailsTabForm {
  padding: 1rem 1vw;
  background-color: #fbfbfb;
  border-radius: 7px;
}

.applicationDetailsTabForm input {
  border-radius: 7px;
  /* height: 40px; */
}

.applicationDetailsTabForm .ant-input-affix-wrapper {
  border-radius: 7px;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-nav .ant-tabs-tab {
  margin: 0 32px 0 0 !important;
}

.inquireDocumentListWrapper {
  background-color: #fbfbfb;
  padding: 2rem;
  margin-bottom: 20px;
}

.inquireDocumentListWrapper div {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.inquireDocumentListWrapper p {
  margin: 0 !important;
  font-family: Poppins-Medium;
  color: #081735;
  margin: 0;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-collapse-content {
  border: none !important;
}

.appButton {
  height: 40px;
  border-radius: 5px;
}

.ensBrandColor {
  color: #007575;
}

.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: transparent !important;
  border: none !important;
  border-radius: none !important;
  cursor: default;
  -webkit-transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  display: none !important;
}

.p-insideSidebarCollapse {
  font-size: 12px !important;
  padding: 8px;
  /* margin-bottom: 0px !important; */
}

.ant-table:not(.ant-table-fixed-header) .ant-table-content {
  overflow-y: hidden !important;
}

.ghen {
  color: red;
  background: red;
}

@media screen and (max-width: 413px) {
  .both-side-padding {
    padding: 2rem 1rem;
  }

  .applicationDetailsTab .ant-tabs-nav-scroll {
    display: flex !important;
    justify-content: flex-start !important;
    padding: 2rem 0;
    background-color: transparent !important;
    border-radius: 7px;
  }
}

/* ///ckedit  */
.ck.ck-editor__editable_inline>:last-child {
  margin-bottom: var(--ck-spacing-large);
  min-height: 100px;
}

.clipText {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: auto;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  /* max-width: 80%; */
}
.faclity__container {
    margin: 20px;
}

.faclity__container p {
    padding: 0px;
    margin: 0px;
}

.faclity__gallery__view {
    height: 100px;
    border-radius: 7px;
    background-color: #F4F3EF;

}

.upload__gallery__container {
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding-right: 20px;
}

.images__container {
    display: flex;
}

.image__container {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative
}

.image__container img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.video__wrapper {
    height: 80px;
    width: 80px;
    border-radius: 10px;
}

.video__wrapper video {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.video_play_icon {

    position: absolute;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;


}

.from__heading {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px !important;
}

.from__notes {
    font-size: 13px;
    font-weight: 500;
    color: #636363;
}

.card__snippet {
    background-color: #F4F3EF;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}

.schedule__delet__icon {
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    height: 30px;
    padding: 0px 20px;
    position: absolute;
    right: 20px;
}

.schedule__title {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
}

.schedule__day {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.schedule__value {
    font-size: 12px;
    font-weight: 400;
    color: #636363;
}

.schedule__date_and__time_container {
    display: flex;
    align-items: center;
}

.schedule__day_title {
    display: flex;
    align-items: center;
    margin: 5px 0px;
}

.hr_row {
    height: 1px;
    background-color: #636363;
    width: 100%;
    margin: 0px 10px;
    opacity: 0.3;
}

.schedule__slots {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two items in a row */
    gap: 10px;
    /* Adjust the gap as needed */
}

.image__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: wheat;
    border-radius: 10px;
}

/* .image__container:hover .image__overlay {
    opacity: 1;
} */

.image__remove {
    position: absolute;
    top: 3px;
    right: 5px;
    color: white;
    cursor: pointer;
    z-index: 99;
}

.lable_for_fullDay {

    margin-top: -32px !important;
    padding-bottom: 10px !important;

}

.facility_pricing_slots_container {
    background-color: white;
    border-radius: 7px;
    border: 1px solid rgb(192, 187, 187);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-top: 20px;
}

.facility_pricing_slot_heading {
    padding: 0;
    margin-bottom: 20px !important;
    font-weight: bold;
    font-size: 15px;
}

.facility_pricing_slots_container Input {
    /* width: 40%; */
}
.ltb_slot_container {
    /* background-color: white; */
    height: 30px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    color: #636363;
    margin: 10px 0;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-weight: bold;
}

.ltb_slot_container.selected {
    background-color: #007B85;
    color: white;
    font-weight: bold;
    /* Other styles for selected slots */
}

.ltb_slot_container p {
    padding: 0;
    margin: 0;
}

.ltb_slot_container:hover {
    cursor: pointer;
}

.no_slots_availabe_message {
    margin: 10px 20px !important;
}
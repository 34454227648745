.mark_arrival_parent_container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    background-color: #F4F3EF;
}

.mark_arrival__container {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    background-color: #F4F3EF;
    width: 30%;
}



.mark_arrival__container p {
    padding: 0;
    margin: 0;
}

.mark_arrival__header {
    padding: 20px;
    text-align: center;
    position: fixed;
    top: 0;
    /* width: 100%; */
    background-color: #F4F3EF;
    display: block;
    left: 0;
    width: 100%;
}

.mark_arrival__header p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #636363;

}

.mark_arrival__body {
    overflow-y: auto;
    flex-grow: 1;
    padding: 10px;
    scrollbar-width: thin;
    margin-top: 60px;
    margin-bottom: 100px;

    /* Firefox */
}

.m_a_facility {
    background-color: white;
    height: 100px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    /* Horizontal offset, vertical offset, blur radius, color */
    display: flex;
    align-items: center;
    padding: 5px 15px;
    gap: 10px;
}

.m_a_facility_cover {
    height: 80%;
    width: 25%;
    background-color: #888;
    border-radius: 5px;
    overflow: hidden;
}

.m_a_facility_cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.m_a_facility_detail p {
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #1E2336;
}

.m_a_facility_detail span {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #1E2336;
}


.m_a_section {
    margin: 20px 0px;
    padding: 0px 5px;
}


.m_a_section p {
    color: #888;
}

.m_a_attendees {
    margin: 10px 0px;
    /* padding: 10px 0px; */
}



.m_a_attendees p {
    color: #888;
}

.m_a_attendee {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: thin;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    height: 50px;
}

.m_a_attendee::-webkit-scrollbar {
    display: none;
    /* WebKit browsers */
}

.m_a_attendee span {
    margin-right: 10px;
    margin-top: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 2px 10px;
    height: 30px;
    /* Optional: Add spacing between names */
}

.m_a_sub_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ma_row {
    height: 1px;
    background-color: #636363;
    margin: 0px 10px;
    opacity: 0.3;

}

.m_a_section_slot {
    margin: 20px 0px;
    padding: 0px 5px;
}

.m_a_section_slot p {
    color: #888;
    margin-bottom: 10px;
}

.date_slot {
    margin-bottom: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 5px 10px;
    color: #1E2336;
    font-size: 14px;
}

.m_a_button_container {
    position: fixed;
    left: 0;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;

}

.m_a_button {
    background-color: #007B85;
    height: 50px;
    width: 29%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    color: white;

}

.m_a_button_default {
    background-color: transparent;
    border: 1px solid #888;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    color: #1E2336;
    margin-top: 20px;
}

/* CustomModal.css */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 28%;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.m_a_spiner {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m_a_invalid_booking {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.m_a_invalid {
    height: 30vh;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    border-radius: 10px;
    flex-direction: column;
    gap: 20px;
    font-size: 20px;
    color: #1E2336;
    padding: 0;
    margin: 0;

}

.m_a_slot_section {
    width: 100%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    padding: 0px 10px;
    overflow: hidden;
}

.m_a_checkbox {
    /* margin: 10px 10px; */
    padding-bottom: 20px;
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;

}

.m_a_button.disabled {
    background-color: #d3d3d3;
    /* Light Dark Grey */
    cursor: not-allowed;
}

.mark_arrival__body::-webkit-scrollbar {
    width: 0px !important;
}

.mark_arrival__body::-webkit-scrollbar-thumb {
    background-color: #888;
}

.mark_arrival__body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

@media screen and (max-width:540px) {
    .mark_arrival__container {
        width: 100%;
    }

    .m_a_button {
        width: 90%;
    }

    .modal-content {
        width: 90%;
    }
}
.booking_schedule__container {
    background-color: var(--secondary-background);
    padding: 32px 0;
    border-radius: 10px;
    position: relative;
}

.booking_schedule__layout {
    height: auto;
    position: relative;
    margin-top: 20px;
}

.booking_schedule__body {
    display: flex;
    width: 100%;
    height: 70px;
}

.booking_schedule__left {
    width: 10.4%;
    display: flex;
    position: relative;
    justify-content: center;
}

.booking_schedule__left p {
    position: absolute;
    top: -13px;
    right: 3px;
    font-size: 12px;
    font-weight: 500;
}

.booking_schedule__right {
    position: relative;
    width: 89.6%;
    border: 1px solid #DDDDDD;
    border-top: 0;
    border-right: 0;
}



.single_schedule_container {
    position: absolute;
    top: 0;
    /* width: 89.5%; */
    left: 10.5%;
    margin: 2px 0;
    border-radius: 4px;
    height: 62px;
    z-index: 10;
    padding: 5px 1rem;
    display: flex;
    cursor: pointer;
}

.full_day_schedule_container {
    position: relative;
    /* top: 0; */
    /* width: 89.5%; */
    left: 10.5%;
    margin: 2px 0;
    border-radius: 4px;
    height: 62px;
    z-index: 10;
    padding: 5px 1rem;
    display: flex;
    cursor: pointer;
    /* margin: 10px 0; */
}





.single_schedule_detail {
    display: flex;
    align-items: center;
    width: 70%;
}

.single_schedule_detail p {
    margin: 0;
    padding: 0;
    color: white;
}

.single_schedule_detail_timing {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single_schedule_detail_timing p {
    padding: 0;
    margin: 0;
    color: white;
    font-size: 14px;
}



.single_event_full_detail {
    position: absolute;
    z-index: 211;
    height: auto;
    background-color: #D2DAFB;
    box-shadow: 0px 1px 2px rgb(0 0 0 0.1);
    width: 100%;
    display: none;
    opacity: 0;
    bottom: 35px;
    left: 0;
    border-radius: 5px;
    overflow: hidden;
    font-size: 12px;


}

.single_event_full_detail p {
    margin: 0;
    padding: 3px 0;
}

.single_event_full_detail span {
    margin: 0;
    padding: 0;
    color: black !important;

}

.single_event_inner_detail {
    height: 100%;
    width: 100%;
    padding: 1rem;
}


.single_schedule_container:hover {
    cursor: pointer;
}

.single_schedule_container:hover .single_event_full_detail {
    opacity: 1;
    display: block;
}

.details__button_schedule {
    height: 30px;
    color: #007B85;
    display: flex;
    margin: 10px 0;
    font-size: 14px;
}
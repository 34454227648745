.placeholder_table_header {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;
    background-color: #007B85;
    border-radius: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.category_title {
    font-size: 16px;
    color: #007B85
}

.placeholder_table_header .title_one {
    font-size: 14px;
    color: #FFFFFF;
}

.placeholder_table_header .title_two {
    font-size: 14px;
    color: #FFFFFF;
}

.placeholder_table_data_container {
    overflow: scroll;
    height: 400px;
}

.placeholder_table_data {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;
    background-color: #F9F9F9;
    border-radius: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 2px;
    border-radius: 5px;
}

.placeholder_table_row_item {
    font-size: 12px;
    line-height: 1.2em;
    /* Adjust the line height as needed */
    max-height: 3.6em;
    /* Adjust the max height to limit the number of lines */
    overflow: hidden;
    /* width: 150px; */
}

.placeholder_info_description {
    font-size: 14px;
    line-height: 1.5em;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #333333;
}
.booking_layout__container {
    margin: 1rem 20px;
    position: relative;
}

.booking_layout__container h3 {
    margin-bottom: 1rem;
}

.bookings__layout {
    display: flex;
    width: 100%;
    gap: 1rem;
    height: 100%;
}

.bookings__layout__left {
    width: 30%;
    height: auto;
    border-radius: 10px;
    margin-top: 20px;
}

.bookings__layout__right {
    width: 70%;
    height: auto;
    border-radius: 10px;

}

.booking-dots-container {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
}

.booking-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 1px;
}
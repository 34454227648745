.general_container__scc {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
}

.scc_dashboard_tabs_container {
    height: 100px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 15px 0px;
}

.scc_d_t_body {
    display: flex;
    width: 100%;
    gap: 20px;
}

.scc_d_tab {
    height: 50px;
    background-color: #E9E9E9;
    width: 200px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    cursor: pointer;

}

.scc_dashboard_filters {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 5px,
}
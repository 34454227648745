.rh-log-item {
    background-color: #f0f0f0;
    padding: 5px 12px;
    width: 100%;
    max-width: 350px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 15px;
}
.rh-log-row {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}
.rh-log-icon {
    margin-right: 10px;
}
.rh-log-row p{
padding: 0;
margin: 0;
}
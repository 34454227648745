.create-company-btn {
  background-color: #1876cc !important;
  color: white !important;
  border-radius: 4px !important;
  &:hover {
    color: white !important;
  }
}

.d-flex .ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bebcbc;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 123, 133, 0.2);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007b85;

}

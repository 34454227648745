.sch_info_view {
    display: flex;
    align-items: center;
}

.sch_info_title {
    width: 130px;
}

.sch_info_header {
    color: #007B85;
    padding-bottom: 10px;
}
.expanded__row__values {
    display: flex;
    align-Items: center;
    margin-Top: 10px;
    justify-content: center;
}

.terms__action_button {
    margin-right: 10px;
    height: 35px;
    width: 70px;
    border-radius: 10px;
    border: 1px solid #828280db;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.cases_count {
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rebeccapurple;
    margin-left: 30px;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-radio-inner::after {
    position: absolute;
    top: 11px;
    left: 11px;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #007B85;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
}

.save_reward_button {
    display: flex;
    align-items: flex-end;
    padding-top: 40px;
    justify-content: flex-end;
}
.form_item_row_lable {
    font-size: 14px;
    color: black;
    margin: 0;
    padding: 5px 0;

}

.checkBox__item_col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}



.dynamic_form_items_container {
    margin: 0 20px;
}

.approve_all_field {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.approve_all_field p {
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

.schedule_date_container {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.schedule_date_container span {
    /* padding: 0; */
    font-size: 12px;
    color: black;
    font-weight: 500;

}

.end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.schedule_date {
    margin: 0 2px;
    background-color: #DDDDDD;
    padding: 2px 5px;
    border-radius: 20px;
    font-size: 11px;
    color: #6E6E6E;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}
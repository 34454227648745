.top-nav-text{
    color: #000000 !important;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
}

.top-nav-text-value{
  color: #000000 !important;
  font-size: 14px;
  cursor: pointer;
}

.dashboard-image-list{
  height: 200px;
  width: 100%;
  cursor: pointer;
  

  @media only screen and (max-width: 1024px){
    width: 100%;
  }
}

.video-tag{
  width: 99.5%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}

.video-tag-parent{
  height: 100%;
  background: black;
  border-radius: 4px;
}

.image-modal-container{

  .ant-modal-close{
    &:focus{
      outline: none !important;
    }
  }
 
  .ant-modal-body{
    padding: 10px ;

    .modal-image-main{
      width: 100%;
      height: 350px;
    }
  }

  .ant-modal-footer{
    .modal-footer-btn-container{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;

      .btn-image-modal{
        padding: 5px 50px;
        border: none;
        color: #ffffff;
        background: #FF0000;

        &:focus{
          color: #ffffff;
        }

        &:hover{
          color: #ffffff;
        }
      }

      .btn-image-pass{
        background: green;
        color: #ffffff;
        border: none;

        &:focus{
          background: green;
          color: #ffffff;
        }
        &:hover{
          background: green;
          color: #ffffff;
        }
      }
    }
  }
}


.check-image-icon{
  position: absolute;
  z-index: 5;
  bottom: 17%;
  left: 50%;
  font-size: 20px;
  transform: translate(-50%);
  height: 36px;
  width: 36px;
  background: green;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-image-icon{
  background: #FF0000;
}

.video-image-icon{
  position: absolute;
  top: 0%;
  right: 5%;
  font-size: 20px;
  color: #F4F3EF;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  display: none;
}
.section__container {
    border: 1px solid #BFBDBA;
    border-radius: 7px;
    width: 100%;
}

.section_title__continer {
    height: 50px;
    display: flex;
    background-color: ghostwhite;
    border-radius: 7px;
    align-items: center;
    padding-left: 20px;
}

.section_values__continer {
    height: 70px;
    display: flex;
    border-radius: 7px;
    align-items: center;
    padding-left: 20px;
}

.title_body {
    width: 16.6%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
}

.section_location {
    height: 3em;
    /* Display the text in multiple lines with ellipsis for overflow */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to display (in this case, 3 lines) */
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.section_title {
    color: black;
    font-weight: 400;
    margin: 0;
    padding: 0;
}